import DashboardCarousel from "./components/DashboardCarousel";
import { TORUS_NETWORK_TYPE, WalletCore } from "@zbyteio/zbyte-wallet-sdk-core";
import {
  NetworkConfig,
  getBlockchainNetwork,
  Web3AuthLoginParams,
} from "@zbyteio/zbyte-common";
import { Web3AuthProvider } from "@zbyteio/zbyte-wallet-sdk-core";
import { KeycloakContext } from "../../context/keycloak";
import BarcodeList from "../../components/barcodeList";
import { useContext, useEffect, useRef, useState } from "react";
import { Triangle } from "react-loader-spinner";
import { PoweroffOutlined } from "@ant-design/icons";

function DashboardContainer(this: any) {
  const auth = useContext(KeycloakContext);
  const [isMintingNFT, setIsMintingNFT] = useState(false);
  const [noAttendee, setNoAttendee] = useState<boolean>(false);
  const config = {
    networkType: process.env.REACT_APP_AUTH_NETWORK_TYPE as TORUS_NETWORK_TYPE,
    web3AuthClientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
    enableLogging: (process.env.REACT_APP_ENABLE_LOGGING as string) === "true",
    verifierName: process.env.REACT_APP_VERIFIER as string,
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    domain: process.env.REACT_APP_DOMAIN as string,
  };
  const web3Auth = new Web3AuthProvider(config);
  const walletProvider: IWalletProvider = web3Auth;
  const walletCore = new WalletCore(walletProvider, getBlockchainNetwork(137));
  window.apiBaseUrl = "https://dplat.zbyte.io"; //set the location to go recover the share from

  const [isLoading, setIsLoading] = useState(true);
  const [attendeeExists, setAttendeeExists] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [barcodeDataList, setBarcodeDataList] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState("Connecting Wallet...");
  const initRef = useRef<boolean>(false); // Avoid reinitialize

  useEffect(() => {
    async function handleRefresh() {
      if (auth) {
        try {
          // Read user session from localstorage
          const info = JSON.parse(localStorage.getItem("account") as string);
          if (!initRef.current && info?.token) {
            (async () => {
              // Use fresh tokens to login in wallet
              initRef.current = true;
              await auth?.refreshSession(-1);
            })();
          }
        } catch (err) {
          console.log(err);
          auth?.logoutSession();
        }
      }
    }

    async function checkAttendee(name: string, email: string, token: string) {
      await handleRefresh();
      try {
        if (token !== null) {
          const response = await fetch(
            `https://dplat.zbyte.io/mint-ticket-service/check-attendee-exists?name=${name}&email=${email}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          if (data.message === "Attendee exists") {
            setAttendeeExists(true);
            const walletAdd = await walletCore.getAddress();

            const barcodesPending = await barcodesToMint(
              name,
              email,
              walletAdd,
              token
            );

            if (barcodesPending.length > 0) {
              for (let i = 0; i < barcodesPending.length; i++) {
                console.log("minting" + barcodesPending[i]);
                try {
                  await mintUserNFT(name, email, token, barcodesPending[i]);
                } catch (err) {
                  console.log(err);
                }
              }
            }

            // Retrieve barcode data after minting NFT
            try {
              const barcodeList = await getBarcodes(await walletAdd, token);
              if (barcodeList.length !== 0) {
                try {
                  const barcodeData = await getBarcodeData(barcodeList, token);
                  setBarcodeDataList(barcodeData);
                } catch (error) {
                  console.error("Error retrieving barcode data:", error);
                  // Handle error appropriately
                }
              }
            } catch (error) {
              console.error("Error retrieving barcode list:", error);
              // Handle error appropriately
            }
          } else {
            setNoAttendee(true);
          }
        }
      } catch (error) {
        console.error("Error checking attendee existence:", error);
        // Handle error appropriately
      } finally {
        setIsLoading(false); // Set loading to false regardless of the result
      }
    }

    async function connectWalletAndCheckAttendee() {
      console.log("Connecting wallet");
      const info = JSON.parse(localStorage.getItem("account") as string);
      if (!info?.token) {
        // If token is empty, redirect to login page
        window.location.href = "/";
        return;
      }

      try {
        const tokenData = JSON.parse(localStorage.getItem("account"));
        // Extract name and email from token data
        const token = tokenData.token;
        const name = tokenData.name;
        const email = tokenData.email;
        await connectWallet();
        await checkAttendee(name, email, token);
      } catch (error) {
        console.error("Error connecting wallet:", error);
        setIsLoading(false); // Set loading to false if there's an error connecting wallet
        auth?.logoutSession();
        localStorage.setItem("account", "");
        window.location.href = "/";
      }
    }

    setTimeout(connectWalletAndCheckAttendee, 4000);
  }, []);

  async function barcodesToMint(name, email, walletAddress, token) {
    try {
      const response = await fetch(
        `https://dplat.zbyte.io/mint-ticket-service/check-barcodes-not-in-sheet?email=${email}&name=${name}&walletAddress=${walletAddress}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoadingMessage("Checking your tickets...");
      const data = await response.json();
      console.log(data);
      return data.barcodesNotInSheet || []; // Return the array or an empty array if it's not present
    } catch (error) {
      console.error("Error:", error);
      return []; // Return an empty array if an error occurs
    }
  }

  async function getBarcodeData(barcodeList, token) {
    try {
      const barcodeString = barcodeList.join(",");
      setLoadingMessage("Loading your tickets...");
      const response = await fetch(
        `https://dplat.zbyte.io/mint-ticket-service/barcode-data?barcodes=${barcodeString}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("Barcode data response:", data);
      // Handle response based on success or failure
      if (response.ok) {
        console.log("Barcode data retrieved successfully!");
        // Extract and return barcode data
        return data;
      } else {
        console.error("Error retrieving barcode data:", data.error);
        // Handle error appropriately
        return [];
      }
    } catch (error) {
      console.error("Error retrieving barcode data:", error);
      // Handle error appropriately
      return [];
    }
  }

  async function connectWallet() {
    console.log("Connecting wallet");
    setLoadingMessage("Connecting Wallet...");
    const info = JSON.parse(localStorage.getItem("account") as string);
    console.log(info);
    setName(info.name);
    setEmail(info.email);
    const loginParams: Web3AuthLoginParams = {
      clientId: process.env.REACT_APP_CLIENT_ID as string,
      domain: process.env.REACT_APP_DOMAIN as string,
      tokenExpiry: Number(process.env.REACT_APP_TOKEN_EXPIRY || ""),
      typeOfToken: process.env.REACT_APP_TYPE_OF_TOKEN,
      verifier: process.env.REACT_APP_VERIFIER as string,
      accessToken: info.token,
    };

    if (!walletCore.isConnected()) {
      walletCore.injectAuthVerifier(loginParams);
      await walletCore.connect();

      console.log(await walletCore.getAddress());
      setWalletAddress(await walletCore.getAddress());
      console.log("Wallet connected");
    }
  }

  async function mintUserNFT(name, email, token, barcode, retryCount = 1) {
    try {
      setLoadingMessage(
        "NFT is minting. Please do not refresh or hit the back button. This may take a minute!"
      );
      const response = await fetch(
        "https://dplat.zbyte.io/mint-ticket-service/mint-user-nft",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: name,
            email: email,
            walletAddress: await walletCore.getAddress(),
            barcode: barcode, // You need to provide the wallet address here
          }),
        }
      );
      const data = await response.json();
      console.log("NFT minting response:", data);
      // Handle response based on success or failure
      if (response.ok) {
        console.log("NFT tickets minted successfully!");
        // Proceed with further actions if necessary
      } else {
        console.error("Error minting NFT tickets:", data.error);
        // Retry logic
        if (retryCount > 0) {
          console.log(`Retrying minting, attempts left: ${retryCount}`);
          await mintUserNFT(name, email, token, barcode, retryCount - 1);
        } else {
          console.error("Maximum retry attempts reached, minting failed.");
          // Show user-friendly message when maximum attempts reached
          setLoadingMessage(
            "The blockchain is currently too busy. Please try again later."
          );
        }
      }
    } catch (error) {
      console.error("Error minting NFT tickets:", error);
      // Retry logic
      if (retryCount > 0) {
        console.log(`Retrying minting, attempts left: ${retryCount}`);
        await mintUserNFT(name, email, token, barcode, retryCount - 1);
      } else {
        console.error("Maximum retry attempts reached, minting failed.");
        // Show user-friendly message when maximum attempts reached
        setLoadingMessage(
          "The blockchain is currently too busy. Please try again later."
        );
      }
    }
  }

  async function getBarcodes(walletAddress, token) {
    try {
      const response = await fetch(
        `https://dplat.zbyte.io/mint-ticket-service/barcodes?walletAddress=${walletAddress}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoadingMessage(
        "NFT is minting. Please do not refresh or hit the back button. This may take a minute!"
      );
      const data = await response.json();
      console.log("Barcodes response:", data);
      // Handle response based on success or failure
      if (response.ok) {
        console.log("Barcodes retrieved successfully!");
        // Extract and return barcodes
        return data.barcodes;
      } else {
        console.error("Error retrieving barcodes:", data.error);
        // Handle error appropriately
        return [];
      }
    } catch (error) {
      console.error("Error retrieving barcodes:", error);
      // Handle error appropriately
      return [];
    }
  }

  async function logout() {
    auth?.logoutSession();
  }

  return (
    <main className='center-body-container'>
      <div className='dashboard-banner'>
        <button
          style={{
            position: "absolute",
            fontWeight: "bold",
            right: "0",
            backgroundColor: "transparent",
            zIndex: 1,
            color: "white",
            border: "none",
            padding: "15px 30px",
            textAlign: "center",
            textDecoration: "none",
            fontSize: "25px",
            top: "25px",
            cursor: "pointer",
          }}
          className={"logout-btn"}
          onClick={logout}
        >
          <PoweroffOutlined />
        </button>
        <div className='login-container'>
          <div className='login-text'>
            <div className='title-lg'>TEFFA</div>
            <div className='text-1'>Doha 2024 Collectible NFT</div>
          </div>
        </div>
      </div>
      <div className='dashboard-main'>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              width: "100%",
              justifyContent: "center",
              height: "35vh",
            }}
          >
            <Triangle
              visible={true}
              height='80'
              width='80'
              color='#4fa94d'
              ariaLabel='triangle-loading'
              wrapperStyle={{}}
              wrapperClass=''
            />
            {loadingMessage}
          </div>
        ) : (
          <>
            <DashboardCarousel barcodeList={barcodeDataList} />
            {noAttendee && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  width: "100%",
                  justifyContent: "center",
                  height: "35vh",
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                <div>
                  We couldn't find your registration for the TEFFA event. Please
                  ensure you're logged in with the same email you used to
                  purchase tickets from Eventbrite. If you haven't registered
                  yet, please visit
                  <a
                    href='https://www.eventbrite.co.uk/e/teffa-ai-r-evolution-registration-806787943457'
                    style={{ cursor: "pointer" }}
                  >
                    &nbsp;link&nbsp;
                  </a>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className='login-footer'>
        Copyright © TEFFA All Rights Reserved 2024
      </div>
    </main>
  );
}

interface IWalletProvider {
  /**
   * @description Use to connect the wallet provider.
   */
  connect(): Promise<any>;
  /**
   * @description Use to checked whether wallet provider connected or not.
   */
  isConnected(): boolean;
  /**
   * @description fetch the wallet provider which internally set the provider
   * @param networkConfig Blockchain Network parameters
   */
  getKeyProvider(networkConfig: NetworkConfig): Promise<IKeyProvider>;
}

interface IKeyProvider {
  /**
   * @description Name of the key provider
   */
  readonly serviceProviderName: string;
  /**
   * @description Add Network to the given key provider
   * @param networkConfig Network Information of the blockchain
   */
  addChain(networkConfig: NetworkConfig): Promise<void>;
  /**
   * @description Switch default network to the given key provider,
   *              switchChain only supported on the added chain.
   * @param chainId chainId in hex string format e.g 0x89(137)
   */
  switchChain(chainId: string): Promise<void>;
  getProvider(): any;
}

export default DashboardContainer;
