import { Button, ConfigProvider, ThemeConfig } from "antd";
import { FC } from "react";

type IProps = {
  text: string;
  onClick: () => void;
  icons?: string;
};

const AuthButton: FC<IProps> = ({ text, onClick, icons }) => {
  const theme: ThemeConfig = {
    components: {
      Button: {
        borderRadius: 8,
        colorBorder: "rgba(82, 62, 138, 0.54)",
        colorBgContainer: "#F8F5FF",
        paddingBlock: 10,
        paddingInline: 36,
        controlHeight: 56,
        fontSize: 18,
      },
    },
  };
  return (
    <ConfigProvider theme={theme}>
      <Button type="default" onClick={onClick}>
        {icons && <img src={icons} alt="" style={{ marginRight: 10 }} />}
        <span>{text}</span>
      </Button>
    </ConfigProvider>
  );
};

export default AuthButton;
