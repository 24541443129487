import { Button, Carousel } from "antd";
import React, { useEffect, useRef, useState } from "react";
import img1 from "../../../assets/images/VIP1 1.png";
import qr from "../../../assets/images/qr.png";
import Wallet from "../../../assets/icons/Wallet.svg";
import Download from "../../../assets/icons/Download.svg";
import Left from "../../../assets/icons/left.svg";
import right from "../../../assets/icons/right.svg";
import QRCode from "react-qr-code";
import jsPDF from "jspdf";
import { toPng } from "html-to-image";
import html2canvas from "html2canvas";

// interface BarcodeListProps {any}
export interface Barcode {
  barcode: string;
  eventName: string;
  hash: string;
  uri: string;
}

const DashboardCarousel = (props: any) => {
  const carouselRef = useRef(null);
  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  const settings = {
    nextArrow: <img src={right} alt='' />,
    prevArrow: <img src={Left} alt='' />,
  };

  const CarouselItem = (prop: Barcode) => {
    const [imageUrl, setImageUrl] = useState(null);
    const component1Ref = useRef<HTMLDivElement>(null);
    const component2Ref = useRef<HTMLImageElement>(null);

    const downloadPdf = async () => {
      const pdf = new jsPDF("p", "pt", "a4");

      const component1 = component1Ref.current;
      const component2 = component2Ref.current;

      if (!component1 || !component2) {
        console.error("One of the components is missing.");
        return;
      }

      // Add Component 2 as an image to the first page
      const imageDataUrl2 = component2.src;
      pdf.addImage(
        imageDataUrl2,
        "JPEG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      );

      // Add a new page
      pdf.addPage();

      // Add big heading on the second page
      pdf.setFontSize(24); // Set font size for the heading
      pdf.setFont("helvetica", "bold"); // Set font style to bold
      pdf.text(
        "Your TEFFA Ai R Evolution Ticket",
        pdf.internal.pageSize.getWidth() / 2,
        50,
        { align: "center" }
      ); // Center-align the heading

      // Add text below the heading
      const textLines = [
        { text: prop.eventName, fontSize: 18, fontWeight: "bold" },
        { text: "Digital Certificate of Authenticity (NFT)", fontSize: 12 },
        {
          text: "Original Artwork by © Aude Rech All rights reserved.",
          fontSize: 12,
        },
      ];
      const lineHeight = 20; // Adjust line height as needed
      const pageCenterX = pdf.internal.pageSize.getWidth() / 2; // Calculate the center position of the page
      const textX = pageCenterX; // X position for center alignment
      let currentY = 120; // Initial Y position below the heading
      textLines.forEach((line) => {
        const textWidth =
          (pdf.getStringUnitWidth(line.text) * line.fontSize) /
          pdf.internal.scaleFactor; // Calculate the width of the text
        const textY = currentY; // Y position
        pdf.setFontSize(line.fontSize);
        pdf.setFont("helvetica", line.fontWeight || "normal"); // Set font weight
        pdf.text(line.text, textX, textY, { align: "center" }); // Center-align the text
        currentY += lineHeight; // Increment Y position for the next line
      });

      // Convert Component 1 to canvas
      const canvas1 = await toCanvas(component1);

      // Add Component 1 as an image to the PDF
      const imageDataUrl1 = canvas1.toDataURL("image/jpeg", 1.0);
      const initialImageWidth = 200; // Initial image width
      const increasedImageWidth = initialImageWidth * 1.2; // Increased image width by 20%
      const initialImageHeight = 200; // Initial image heightf
      const imageX = pageCenterX - increasedImageWidth / 2; // Horizontal position to center-align the image
      const imageY = currentY; // Vertical position
      pdf.addImage(
        imageDataUrl1,
        "JPEG",
        imageX,
        imageY,
        increasedImageWidth,
        initialImageHeight // Keep the height the same
      );

      // Save the PDF
      pdf.save(prop.eventName + ".pdf");
    };

    const toCanvas = async (componentRef: HTMLDivElement) => {
      const canvas = await html2canvas(componentRef);
      return canvas;
    };

    useEffect(() => {
      async function fetchImageUrl() {
        try {
          // Fetch JSON data from the provided URI
          const response = await fetch(prop.uri);
          const jsonData = await response.json();

          // Extract the image URL from the JSON data
          const image = jsonData.image;
          setImageUrl(image);
        } catch (error) {
          console.error("Error fetching image URL:", error);
        }
      }

      fetchImageUrl();
    }, [prop.uri]);

    return (
      <div className='ds-carousel-item'>
        <div className='ds-carousel-item-content'>
          <div className='ds-ca-text'>
            <div
              className='t1'
              style={{ fontWeight: "bold", fontSize: "1.5rem" }}
            >
              {prop.eventName}
            </div>
            <div className='t2'>Digital Certificate of Authenticity (NFT)</div>

            <div className='t2'>
              Original Artwork by © Aude Rech All rights reserved.
            </div>
            <div className='qr-content' ref={component1Ref}>
              {/* <img src={qr} alt="" /> */}
              <QRCode value={prop.barcode} />
            </div>
            <button
              className='cs-btn'
              onClick={() =>
                window.open("https://polygonscan.com/tx/" + prop.hash, "_blank")
              }
            >
              <img src={Wallet} alt='' /> CLICK TO VIEW NFT ON POLYGONSCAN
            </button>
            <button className='cs-btn' onClick={downloadPdf}>
              <img src={Download} alt='' /> CLICK TO DOWNLOAD NFT TICKET
            </button>
          </div>
          <div className='ds-ca-img'>
            <img src={imageUrl} alt='' ref={component2Ref} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='dashboard-carousel' id='dashboard-carousel'>
      <Carousel
        afterChange={onChange}
        //getPopupContainer={() => document.getElementById("dashboard-carousel")}
        ref={carouselRef}
        arrows
        dots={{
          className: "cs-dots",
        }}
        {...settings}
      >
        {props.barcodeList?.map((item: any) => (
          <CarouselItem key={item.barcode} {...item}></CarouselItem>
        ))}
      </Carousel>
    </div>
  );
};

export default DashboardCarousel;
