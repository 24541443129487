// import Header from "../../components/Layout/Header";
import googleIcon from "../../assets/icons/flat-color-icons_google.svg";
import windowsIcon from "../../assets/icons/logos_microsoft-icon.svg";
import AuthButton from "../../components/AuthButton";

import React, { useContext, useEffect, useState } from "react";
import { KeycloakContext } from "../../context/keycloak";

function LoginContainer() {
  const auth = useContext(KeycloakContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeKeycloak = async () => {
      try {
        await auth.initSession(); // Wait for Keycloak initialization
        setIsLoading(false); // Mark initialization as complete
      } catch (error) {
        console.error("Keycloak initialization error:", error);
        setIsLoading(false); // Handle initialization error
      }
    };
  });

  useEffect(() => {
    try {
      // Redirect logged in user to dashboard
      if (JSON.parse(localStorage.getItem("account") as string)) {
        window.location.href = "/dashboard";
      }
    } catch (err) {
      console.log(err);
      auth?.logoutSession();
    }
  }, []);

  return (
    <main style={{ height: "100%" }}>
      <div className='center-container login-main'>
        <div className='login-container'>
          <div className='teffa-title'>
            <div className='title-lg'>TEFFA</div>
            <div className='text-1'>Doha 2024 Collectible NFT</div>
          </div>
          <div className='auth-buttons'>
            <AuthButton
              onClick={() => auth?.loginUsingIdp("google")}
              text='Sign in with Google'
              icons={googleIcon}
            />
            <AuthButton
              onClick={() => auth?.loginUsingIdp("microsoft")}
              text='Sign in with Microsoft'
              icons={windowsIcon}
            />
          </div>

          <div className='text-2'>powered by zbyte </div>
        </div>
      </div>
      <div className='login-footer'>
        Copyright © TEFFA All Rights Reserved 2024
      </div>
    </main>
  );
}

export default LoginContainer;
