import UserIcon from "../../assets/icons/user-icon.svg";
import { Avatar } from "antd";
import CopyIcon from "../../assets/icons/content_copy.svg";
import "./style.scss";

const AvatarInfoCard = () => {
  return (
    <div className="avatar-content">
      <div className="avatar-info-card">
        <Avatar
          size={87}
          style={{ backgroundColor: "#EEEEEE" }}
          icon={<img src={UserIcon} alt="" />}
          className="avatar-info"
        />
        <div className="avatar-info-card-text">
          <div className="avatar-info-card-title">Hello</div>
          <div className="avatar-info-card-name">Wade Warren</div>
        </div>
      </div>
      <div className="info-card">
        <div className="title-1">zault address</div>
        <div className="title-2">283h976qwe9ry25r817gf31f4fk17f9g</div>
        <img src={CopyIcon} alt="" className="copy-btn" />
      </div>
    </div>
  );
};

export default AvatarInfoCard;
