import React from "react";
// import ReactDOM from "react-dom/client";
import { render } from "react-dom";
import "antd/dist/reset.css";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DashboardContainer from "../src/pages/dashboard";
import ListDetailContainer from "../src/pages/list-detail";
import LoginContainer from "../src/pages/login";

import KeycloakProvider from "./context/keycloak";

const root = document.getElementById("root");
// ReactDOM.render(
//   <React.StrictMode>
//     <KeycloakProvider>
//       <App />
//     </KeycloakProvider>
//   </React.StrictMode>,
//   root
// );

const router = createBrowserRouter([
  {
    path: "/dashboard",
    element: <DashboardContainer />,
  },
  {
    path: "/",
    element: <LoginContainer />,
  },
  {
    path: "/list-detail/:id?",
    element: <ListDetailContainer />,
  },
]);

const container = document.getElementById("root");

render(
  <React.StrictMode>
    <KeycloakProvider>
      <RouterProvider router={router} />
    </KeycloakProvider>
  </React.StrictMode>,
  container
);

// ReactDOM.createRoot(container!).render(
//   <React.StrictMode>
//     <KeycloakProvider>
//       <RouterProvider router={router} />
//     </KeycloakProvider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
