import "../style.scss";
import Qr from "../../../assets/images/barkot.png";
import Line1 from "../../../assets/images/Line-1.png";
import Line2 from "../../../assets/images/Line-2.png";
import Link from "../../../assets/images/new-link-1.png";
import Check from "../../../assets/images/check.png";
import Calendar from "../../../assets/icons/Calendar.svg";

import { FC } from "react";

type IProps = {
  type: "dashboard" | "detail";
};

const DashboardCard: FC<IProps> = ({ type }) => {
  return (
    <div className='dashboard-card'>
      <div className='title-1'>
        <span>TEFFA</span> NFT ticket
      </div>
      <div className='text'>
        {" "}
        <img src={Calendar} alt='' /> DOHA DESIGN DISTRICT April 16 - 18 2024
      </div>
      {type === "detail" && (
        <>
          {" "}
          <div className='about-content'>
            <div className='qr-content'>
              <img className='line-image rotate1' src={Line2} alt='' />
              <img width={250} height={250} src={Qr} alt='' />
              <img className='line-image rotate2' src={Line1} alt='' />
            </div>
            <div className='link-content'>
              <img className='new-image' src={Link} alt='' />
            </div>
          </div>
          <div className='check-content'>
            Successfully claimed <img src={Check} alt='' />
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardCard;
