import AvatarInfoCard from "../../components/AvatarInfoCard";
import DashboardCard from "../dashboard/components/DashboardCard";

function ListDetailContainer() {
  return (
    <main className="center-body-container">
      <div className="dashboard-container">
        <AvatarInfoCard />
        <DashboardCard type={"detail"} />

        <div className="footer-authenticity">Verify authenticity</div>
      </div>
    </main>
  );
}

export default ListDetailContainer;
